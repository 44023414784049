// 登录接口
import {
	axios
} from '../utils/http.js'
import store from '../store/index.js'
const noticeApi = {
	noticeList: '/v3/Bulletin/GetBulletinsListAdmin',
    noticeadd: '/v3/Bulletin/AddBulletin',
    noticeup: '/v3/Bulletin/UpdateBulletin',
    noticedel: '/v3/Bulletin/DeleteBulletin',
    noticeGetList: '/v3/Bulletin/GetBulletinList',
    noticeIsReadBulletin: '/v3/Bulletin/IsReadBulletin',


    
}

export function noticeIsReadBulletin(data) {
	return axios({
		url: noticeApi.noticeIsReadBulletin,
		method: 'post',
		data
	})
}

export function noticeGetList(data) {
	return axios({
		url: noticeApi.noticeGetList,
		method: 'post',
		data
	})
}

export function noticeList(data) {
	return axios({
		url: noticeApi.noticeList,
		method: 'post',
		data:data
	})
}
export function noticeadd(data) {
	return axios({
		url: noticeApi.noticeadd,
		method: 'post',
		data:data
	})
}

export function noticeup(data) {
	return axios({
		url: noticeApi.noticeup,
		method: 'put',
		data:data,
	})
}
export function noticedel(data) {
	return axios({
		url: noticeApi.noticedel,
		method: 'post',
		data:data
	})
}