<template>
    <div class="">
        <el-drawer :title="titles + '公告'" :visible.sync="drawer" size="70%" :append-to-body="true">
            <div style="padding: 20px;">
                <el-form :rules="rules" ref="ruleForm" label-position="top" :model="form" label-width="80px">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="form.title"></el-input>
                    </el-form-item>
                    <el-form-item label="通知内容" prop="content">
                        <el-input type="textarea" :autosize="{ minRows: 12 }" v-model="form.content"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('ruleForm')">立即提交</el-button>
                        <el-button @click="drawer = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>

import { noticeadd,noticeup } from "@/api/notices.js";
export default {
    props: {
        titles: {
            type: String,
            default: () => '新增'
        },
    },
    data() {
        return {
            drawer: false,
            form: {
                title: '',
                content: '',
            },
            rules: {
                title: [
                    { required: true, message: '请输入标题名称', trigger: 'change' },
                    { min: 1, message: '标题长度不能小于1', trigger: 'change' }
                ],
                content: [
                    { required: true, message: '请输入通知内容', trigger: 'change' }
                ],
            }
        }
    },
    watch:{
        drawer(newData){
            if(newData)if(this.$refs['ruleForm'])this.$refs['ruleForm'].resetFields()
        }
    },
    methods: {
        showDrawer() {
            this.drawer = true
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log('submit!',this.titles);
                    if(this.titles=='新增'){
                        this.noticeAddApi()
                    }else{
                        this.noticeupApi()//编辑
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        async noticeAddApi(){
            const result = await noticeadd(this.form)
            if(result.data.Code==200){
                this.$message.success(result.data.Msg)
                this.$emit('closefn')
                this.drawer = false
            }
        },
        async noticeupApi(){
            const result = await noticeup(this.form)
            if(result.data.Code==200){
                this.$message.success(result.data.Msg)
                this.$emit('closefn')
                this.drawer = false
            }
        }
    }
}
</script>
<style lang="scss">
/* @import url(); 引入css类 */
</style>